mat-radio-button {
  --mdc-radio-selected-icon-color: var(--brt-radio-icon) !important;
  --mdc-radio-selected-hover-icon-color: var(--brt-radio-icon-hover) !important;
  --mdc-radio-selected-focus-icon-color: var(--brt-radio-icon-focus) !important;
  --mdc-radio-disabled-selected-icon-color: var(--brt-radio-icon-active) !important;

  // Ripple //
  --mat-mdc-radio-ripple-color: var(--brt-radio-hover-ripple) !important;
  --mat-mdc-radio-checked-ripple-color: var(--brt-radio-focus-ripple) !important;
}
