$hoverRipple: rgba(54, 90, 152, 0.04);
$focusRipple: rgba(41, 69, 116, 0.12);

mat-checkbox {
  --mdc-checkbox-selected-checkmark-color: var(--brt-checkbox-selected) !important;
  --mdc-checkbox-selected-hover-icon-color: var(--brt-checkbox-icon-hover) !important;
  --mdc-checkbox-unselected-hover-icon-color: var(--brt-checkbox-icon-hover) !important;
  --mdc-checkbox-selected-icon-color: var(--brt-checkbox-icon) !important;
  --mdc-checkbox-selected-pressed-icon-color: var(--brt-checkbox-icon) !important;
  --mdc-checkbox-unselected-focus-icon-color: var(--brt-checkbox-icon-focus) !important;
  --mdc-checkbox-selected-focus-icon-color: var(--brt-checkbox-icon-focus) !important;
  --mdc-checkbox-unselected-icon-color: var(--brt-checkbox-icon-focus) !important;
  --mdc-checkbox-unselected-pressed-icon-color: var(--brt-checkbox-icon) !important;
  --mdc-checkbox-disabled-selected-icon-color: var(--brt-checkbox-color-disabled) !important;
  --mdc-checkbox-disabled-unselected-icon-color: var(--brt-checkbox-color-disabled) !important;

  .mat-mdc-checkbox-disabled label {
    color: var(--brt-checkbocx-disabled) !important;
  }

  &.mat-warn {
    --mdc-checkbox-selected-focus-icon-color: var(--brt-warn-checkbox-icon-focus) !important;
    --mdc-checkbox-selected-hover-icon-color: var(--brt-warn-checkbox-icon-hover) !important;
    --mdc-checkbox-selected-icon-color: var(--brt-warn-checkbox-icon) !important;
    --mdc-checkbox-selected-pressed-icon-color: var(--brt-warn-checkbox-icon-active) !important;
    --mdc-checkbox-unselected-hover-icon-color: var(--brt-warn-checkbox-icon-hover) !important;
    --mdc-checkbox-unselected-focus-icon-color: var(--brt-warn-checkbox-icon-focus) !important;
    --mdc-checkbox-disabled-selected-icon-color: var(--brt-warn-checkbox-selected) !important;
    --mdc-checkbox-disabled-unselected-icon-color: var(--brt-warn-checkbox-selected) !important;
    --mdc-checkbox-unselected-icon-color: var(--brt-warn-checkbox-icon-hover) !important;
    --mdc-checkbox-unselected-pressed-icon-color: var(--brt-warn-checkbox-icon) !important;
  }
}
