.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-center {
  justify-content: center;
  align-content: center;
}

.gap-60 {
  gap: 60px;
}
