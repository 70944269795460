.not-resizable {
  resize: none !important;
}

.avatar-field {
  .mdc-text-field--outlined {
    height: 56px !important;
  }
}

.weight-input {
  .mat-mdc-form-field-error-wrapper {
    width: 125%;
  }
}

mat-form-field {
  --mdc-theme-primary: var(--brt-select-color);
  --mdc-theme-error: var(--brt-select-error-color);
  --mdc-outlined-text-field-error-label-text-color: var(--brt-select-error-color);
  --mdc-outlined-text-field-input-text-color: var(--brt-select-color);
  --mat-select-enabled-trigger-text-color: var(--brt-select-color);
  --mat-form-field-error-text-color: var(--brt-select-error-text-color);

  // FOCUS
  &.mat-focused {
    .mdc-text-field--outlined:not(.mdc-text-field--disabled),
    .mdc-text-field--outlined:not(.mdc-text-field--invalid) .mdc-notched-outline__leading,
    .mdc-text-field--outlined:not(.mdc-text-field--disabled),
    .mdc-text-field--outlined:not(.mdc-text-field--invalid) .mdc-notched-outline__notch,
    .mdc-text-field--outlined:not(.mdc-text-field--disabled),
    .mdc-text-field--outlined:not(.mdc-text-field--invalid) .mdc-notched-outline__trailing {
      border-color: var(--brt-select-focus-border) !important;
    }

    .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-floating-label,
    .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-floating-label--float-above {
      color: var(--brt-select-focus-label-color) !important;
    }
  }

  // HOVER
  &:not(.mat-focused):hover {
    .mdc-text-field--outlined:not(.mdc-text-field--disabled),
    .mdc-text-field--outlined:not(.mdc-text-field--invalid) .mdc-notched-outline__leading,
    .mdc-text-field--outlined:not(.mdc-text-field--disabled),
    .mdc-text-field--outlined:not(.mdc-text-field--invalid) .mdc-notched-outline__notch,
    .mdc-text-field--outlined:not(.mdc-text-field--disabled),
    .mdc-text-field--outlined:not(.mdc-text-field--invalid) .mdc-notched-outline__trailing {
      border-color: var(--brt-select-hover-border) !important;
      border-width: 2px;
    }

    .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-floating-label,
    .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-floating-label--float-above {
      color: var(--brt-select-hover-label-color) !important;
    }
  }

  // INVALID
  .mdc-text-field--outlined.mdc-text-field--invalid .mdc-notched-outline__leading,
  .mdc-text-field--outlined.mdc-text-field--invalid .mdc-notched-outline__notch,
  .mdc-text-field--outlined.mdc-text-field--invalid .mdc-notched-outline__trailing {
    border-color: var(--brt-select-error-color) !important;
  }

  // INVALID HOVER
  &:not(.mat-focused):hover {
    .mdc-text-field--outlined.mdc-text-field--invalid .mdc-notched-outline__leading,
    .mdc-text-field--outlined.mdc-text-field--invalid .mdc-notched-outline__notch,
    .mdc-text-field--outlined.mdc-text-field--invalid .mdc-notched-outline__trailing {
      border-width: 2px;
      border-color: var(--brt-select-error-hover-color) !important;
    }

    .mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled) {
      .mdc-floating-label,
      .mdc-floating-label--float-above {
        color: var(--brt-select-error-hover-color) !important;
      }
    }
  }

  // INVALID FOCUSED
  .mdc-text-field--outlined.mdc-text-field--invalid.mdc-text-field--focused
    .mdc-notched-outline__leading,
  .mdc-text-field--outlined.mdc-text-field--invalid.mdc-text-field--focused
    .mdc-notched-outline__notch,
  .mdc-text-field--outlined.mdc-text-field--invalid.mdc-text-field--focused
    .mdc-notched-outline__trailing {
    border-color: var(--brt-select-error-focus-color) !important;
  }

  .mdc-text-field--outlined.mdc-text-field--invalid:not(
      .mdc-text-field--disabled
    ).mdc-text-field--focused {
    .mdc-floating-label,
    .mdc-floating-label--float-above {
      color: var(--brt-select-error-focus-color) !important;
    }
  }
}

mat-select {
  .mat-mdc-select-arrow-wrapper {
    position: relative;

    .mat-mdc-select-arrow {
      opacity: 0;
    }

    &:before {
      font-family: 'Material Icons';
      content: '\e5cf';
      position: absolute;
      right: 0;
      top: 0;
      font-size: 24px;
    }
  }
}

.weight-error {
  width: 150%;
  margin-left: -3px;
}
.mat-mdc-select-panel,
.cdk-overlay-pane div.mat-mdc-autocomplete-panel {
  background: var(--brt-select-option-theme-color) !important;
}

.mat-mdc-option {
  --mdc-theme-primary: var(--brt-select-option-theme-color) !important;
}

.mat-mdc-option:hover:not(.mdc-list-item--disabled) {
  background: var(--brt-select-option-hover-background) !important;
  color: var(--brt-select-option-hover-color) !important;
}

.mat-mdc-option:focus:not(.mdc-list-item--disabled) {
  background: var(--brt-select-option-focus-background) !important;
  color: var(--brt-select-option-focus-color) !important;
}

.mat-mdc-option.mat-mdc-option-active,
.mat-mdc-option:active:not(.mdc-list-item--disabled) {
  background: var(--brt-select-option-active-background) !important;
  color: var(--brt-select-option-active-color) !important;
}

.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--brt-select-option-active-color) !important;
}

.dimension-autocomplete {
  --mdc-theme-primary: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));

  .mat-mdc-option {
    mat-icon.mat-icon {
      color: var(--mat-option-label-text-color);
    }
  }

  .mat-mdc-option.mat-mdc-option-active,
  .mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(
      .mdc-list-item--disabled
    ) {
    background: transparent;
  }
}
