div.mat-mdc-menu-panel {
  background: var(--brt-menu-item-background);
  color: var(--brt-menu-item-color);
  .mat-mdc-menu-item:hover:not(.mat-mdc-menu-item--disabled) {
    background: var(--brt-menu-item-background-hover);
    color: var(--brt-menu-item-color-hover);
  }

  .mat-mdc-menu-item:focus:not(.mat-mdc-menu-item--disabled) {
    background: var(--brt-menu-item-background-focus);
    color: var(--brt-menu-item-color-focus);
  }

  .mat-mdc-menu-item:active:not(.mat-mdc-menu-item--disabled) {
    background: var(--brt-menu-item-background-active) !important;
    color: var(--brt-menu-item-color-active);
  }

  &.danger {
    .mat-mdc-menu-item:hover:not(.mat-mdc-menu-item--disabled) {
      background: var(--brt-warn-menu-item-background-hover) !important;
      color: var(--brt-warn-menu-item-color-hover) !important;
    }

    .mat-mdc-menu-item:focus:not(.mat-mdc-menu-item--disabled) {
      background: var(--brt-warn-menu-item-background-focus) !important;
      color: var(--brt-warn-menu-item-color-focus) !important;
    }

    .mat-mdc-menu-item:active:not(.mat-mdc-menu-item--disabled) {
      background: var(--brt-warn-menu-item-background-active) !important;
      color: var(--brt-warn-menu-item-color-active) !important;
    }
  }
}
