.mat-mdc-table {
  .mat-mdc-header-row {
    .mat-mdc-header-cell {
      background-color: var(--brt-table-head-background);
      color: var(--brt-table-head-text-color);
    }
  }
  .mat-mdc-row {
    .mat-mdc-cell {
      background-color: var(--brt-table-background);
      color: var(--brt-table-text-color);
    }
  }
}
.mat-mdc-tab-body-content {
  overflow: hidden !important;
}
