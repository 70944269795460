.mat-accordion {
  .mat-expansion-panel {
    box-shadow: none !important;
    background: transparent;

    &:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled='true']) {
      background: transparent !important;
    }

    .mat-expansion-panel-header {
      padding: 0;
    }
  }
  .mat-expansion-panel-header-title,
  .mat-expansion-panel-header-description,
  .mat-expansion-indicator::after {
    color: var(--brt-accordion-title-color);
  }

  .mat-mdc-radio-button .mdc-form-field {
    flex-direction: row !important;
    justify-content: flex-start !important;
  }

  .mat-expansion-panel-header-title {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
  }

  .mat-expansion-panel-body {
    padding: 0;
  }

  .mat-content {
    flex: unset !important;
  }

  .mat-expanded {
    .mat-expansion-indicator {
      top: 2px;
    }
  }

  .mat-expansion-indicator {
    position: relative;
    top: -2px;
  }

  .mat-mdc-slide-toggle {
    width: calc(100% - 4px);
  }

  .mdc-form-field {
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  &.dimension-accordion {
    .mdc-form-field {
      label {
        font-weight: bold;
        padding: 0;
      }
    }
  }
  &.voting-accordion {
    .mat-mdc-slide-toggle {
      .mdc-form-field {
        label {
          font-weight: bold;
          padding: 0;
        }
      }
    }
  }
}
