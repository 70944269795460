@use 'variables' as *;

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  letter-spacing: 0;

  .tooltip--pre-line {
    white-space: pre-line;
  }

  .top-bar--action-section .mdc-form-field {
    display: flex;
    flex-direction: row-reverse;

    label {
      margin-right: 0.75rem;
    }
  }
}

.avatar {
  width: 25px;
  height: 25px;
}
