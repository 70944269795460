.britesizing-body {
  .mat-mdc-fab.mat-accent,
  .mat-mdc-mini-fab.mat-accent {
    --mdc-fab-container-color: var(--brt-background-fab-button);
    border: 1px solid var(--brt-border-fab-button) !important;
    mat-icon.mat-icon {
      color: var(--brt-text-fab-button);
    }
  }
}

.mdc-button {
  --mdc-typography-button-font-size: 16px;
  --mdc-text-button-label-text-size: 16px;
  --mdc-filled-button-container-color: var(--brt-background-flat-button) !important;
  --mdc-filled-button-label-text-color: var(--brt-text-flat-button) !important;
  --mdc-outlined-button-outline-color: 1px solid rgba(63, 106, 179, 0.48) !important;
  --mdc-typography-button-letter-spacing: 0;

  height: var(--brt-button-height) !important;
  padding: 0 16px 0 16px !important;

  &.flat-button {
    --mdc-text-button-label-text-color: var(--brt-background-flat-button);
    --mat-mdc-button-persistent-ripple-color: transparent;

    color: var(--brt-text-flat-button) !important;
    mat-icon {
      color: var(--brt-text-flat-button);
    }
    background: var(--brt-background-flat-button);
    font-style: normal;
    font-weight: 500;

    &:hover {
      background: var(--brt-background-flat-button-hover);
    }

    &:focus {
      background: var(--brt-background-flat-button-focus);
    }

    &:active {
      background: var(--brt-background-flat-button-active);
    }

    &:disabled {
      background: var(--brt-background-flat-button-disabled);
    }

    &.warn {
      --mdc-text-button-label-text-color: var(--brt-warn-background-flat-button);
      --mat-mdc-button-persistent-ripple-color: transparent;
      background: var(--brt-warn-background-flat-button);

      &:hover {
        background: var(--brt-warn-background-flat-button-hover);
      }

      &:focus {
        background: var(--brt-warn-background-flat-button-focus);
      }

      &:active {
        background: var(--brt-warn-background-flat-button-active);
      }
    }
  }

  &.basic-button {
    --mdc-text-button-label-text-color: var(--brt-text-basic-button);
    --mat-mdc-button-persistent-ripple-color: transparent;
    --mat-text-button-state-layer-color: transparent;
    color: var(--brt-text-basic-button);
    font-style: normal;
    font-weight: 500;
    background: transparent;
    border-bottom: 4px solid transparent;
    transition: border-color 0.2s linear;

    &:hover {
      color: var(--brt-text-basic-button-hover);
      border-color: var(--brt-border-basic-button-hover);
    }

    &:focus {
      color: var(--brt-text-basic-button-focus);
      border-color: var(--brt-border-basic-button-focus);
    }

    &:active {
      color: var(--brt-text-basic-button-active);
      border-color: var(--brt-border-basic-button-active);
    }

    &:disabled {
      color: var(--brt-text-basic-button-disabled);
    }

    &.warn {
      color: var(--brt-warn-text-basic-button);

      &:hover {
        color: var(--brt-warn-text-basic-button-hover);
        border-color: var(--brt-warn-border-basic-button-hover);
      }

      &:focus {
        color: var(--brt-warn-text-basic-button-focus);
        border-color: var(--brt-warn-border-basic-button-focus);
      }

      &:active {
        color: var(--brt-warn-text-basic-button-active);
        border-color: var(--brt-warn-border-basic-button-active);
      }
    }
  }

  &.stroke-button {
    --mdc-text-button-label-text-color: var(--brt-text-stroked-button);
    --mat-mdc-button-persistent-ripple-color: transparent !important;
    color: var(--brt-text-stroked-button);
    mat-icon {
      color: var(--brt-text-stroked-button);
    }
    font-style: normal;
    font-weight: 500;
    background: transparent;
    border: 1px solid var(--brt-border-stroked-button);
    box-shadow:
      0px 0px 0px 0px rgba(54, 61, 71, 0.12),
      0px 0px 0px 0px rgba(54, 61, 71, 0.14),
      0px 0px 0px 0px rgba(54, 61, 71, 0.2);
    transition: all 0.2s linear;

    &:hover {
      color: var(--brt-text-stroked-button-hover);
      border: 1px solid var(--brt-border-stroked-button-hover);
      background: var(--brt-background-stroked-button-hover);
    }

    &:focus {
      color: var(--brt-text-stroked-button-focus);
      border: 1px solid var(--brt-border-stroked-button-focus);
      background: var(--brt-background-stroked-button-focus);
    }

    &:active {
      color: var(--brt-text-stroked-button-active);
      border: 1px solid var(--brt-border-stroked-button-active);
      background: var(--brt-background-stroked-button-active);
    }

    &:disabled {
      color: var(--brt-text-stroked-button-disabled);
      border: 1px solid var(--brt-border-stroked-button-disabled);
      mat-icon {
        color: var(--brt-text-stroked-button-disabled);
      }
    }

    &.warn {
      color: var(--brt-warn-text-stroked-button);
      border: 1px solid var(--brt-warn-border-stroked-button);

      &:hover {
        color: var(--brt-warn-text-stroked-button-hover);
        border: 1px solid var(--brt-warn-border-stroked-button-hover);
        background: var(--brt-warn-background-stroked-button);
      }

      &:focus {
        color: var(--brt-warn-text-stroked-button-focus);
        border: 1px solid var(--brt-warn-border-stroked-button-focus);
        background: var(--brt-warn-background-stroked-button-focus);
      }

      &:active {
        color: var(--brt-warn-text-stroked-button-active);
        border: 1px solid var(--brt-warn-border-stroked-button-active);
        background: var(--brt-warn-background-stroked-button-active);
      }
    }
  }
}

.outlined-fab {
  --mdc-fab-container-color: var(--brt-background-outlined-fab-button) !important;
  --mdc-fab-icon-color: var(--brt-icon-outlined-fab-button) !important;
  --mat-mdc-fab-color: var(--brt-icon-outlined-fab-buttonfab-button) !important;
  --mat-icon-color: var(--brt-icon-outlined-fab-button) !important;
  border: 1px solid var(--brt-border-outlined-fab-button) !important;
  box-shadow:
    0px 0px 0px 0px rgba(54, 61, 71, 0.12),
    0px 0px 0px 0px rgba(54, 61, 71, 0.14),
    0px 0px 0px 0px rgba(54, 61, 71, 0.2) !important;
}

.no-shadow {
  box-shadow: none !important;
}

.mat-mdc-fab {
  &.fab-button {
    color: var(--brt-icon-outlined-fab-button) !important;
    background: var(--brt-background-fab-button) !important;

    &:hover {
      background: var(--brt-background-fab-button-hover) !important;
      box-shadow:
        0px 1px 18px 0px rgba(54, 61, 71, 0.12),
        0px 6px 10px 0px rgba(54, 61, 71, 0.14),
        0px 3px 5px -1px rgba(54, 61, 71, 0.2);
    }

    &:focus {
      background: var(--brt-background-fab-button-focus) !important;
      box-shadow:
        0px 1px 18px 0px rgba(54, 61, 71, 0.12),
        0px 6px 10px 0px rgba(54, 61, 71, 0.14),
        0px 3px 5px -1px rgba(54, 61, 71, 0.2);
    }

    &:active {
      background: var(--brt-background-fab-button-active) !important;
      box-shadow:
        0px 5px 22px 4px rgba(54, 61, 71, 0.12),
        0px 12px 17px 2px rgba(54, 61, 71, 0.14),
        0px 7px 8px -4px rgba(54, 61, 71, 0.2);
    }

    &:disabled {
      background: var(--brt-background-fab-button-disabled) !important;
    }
  }
}

.theme-dark .mat-mdc-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: transparent !important;
}
