.mat-mdc-dialog-container .mdc-dialog__surface {
  background-color: var(--brt-dialog-background) !important;
}

.mat-mdc-dialog-actions .mat-mdc-button-base + .mat-mdc-button-base {
  margin-left: 0 !important;
}

.cdk-overlay-container {
  z-index: 99999;
}
