.theme-dark .mdc-switch,
.mdc-switch {
  --mdc-switch-track-width: 40px;
  --mdc-switch-track-height: 24px;
  --mdc-switch-track-shape: 12px;

  --mdc-switch-unselected-track-color: var(--brt-slide-unseleceted-track-color) !important;
  --mdc-switch-unselected-pressed-track-color: var(--brt-slide-unseleceted-track-color) !important;
  --mdc-switch-unselected-hover-track-color: var(--brt-slide-unseleceted-track-color) !important;
  --mdc-switch-unselected-focus-track-color: var(--brt-slide-unseleceted-track-color) !important;

  --mdc-switch-unselected-handle-color: var(--brt-slide-unseleceted-color) !important;
  --mdc-switch-unselected-hover-handle-color: var(--brt-slide-unseleceted-color) !important;
  --mdc-switch-unselected-focus-handle-color: var(--brt-slide-unseleceted-color) !important;
  --mdc-switch-unselected-pressed-handle-color: var(--brt-slide-unseleceted-color) !important;

  --mdc-switch-handle-surface-color: var(--brt-slide-surface-color) !important;

  --mdc-switch-selected-track-color: var(--brt-slide-track-color) !important;
  --mdc-switch-selected-pressed-track-color: var(--brt-slide-track-color) !important;
  --mdc-switch-selected-hover-track-color: var(--brt-slide-track-color) !important;
  --mdc-switch-selected-focus-track-color: var(--brt-slide-track-color) !important;

  --mdc-switch-selected-icon-color: var(--brt-slide-seleceted-color) !important;
  --mdc-switch-selected-handle-color: var(--brt-slide-seleceted-color) !important;
  --mdc-switch-selected-hover-handle-color: var(--brt-slide-seleceted-color) !important;
  --mdc-switch-selected-focus-handle-color: var(--brt-slide-seleceted-color) !important;
  --mdc-switch-selected-pressed-handle-color: var(--brt-slide-seleceted-color) !important;

  .mdc-switch__handle {
    left: 2px;
  }

  &.mdc-switch--selected.mdc-switch--checked > .mdc-switch__handle-track {
    left: -3px;
  }
}

.mdc-switch__icons {
  display: none;
}
.mat-mdc-slide-toggle .mat-internal-form-field {
  --mat-switch-label-text-size: 16px;
}
