:root {
  .theme-dark {
    // BACKGROUND //
    --brt-background-page: #0a0c16;
    --brt-background-menu: rgba(46, 52, 60, 1);
    --brt-background-footer: rgba(46, 52, 60, 1);
    --brt-accent-opacity: #d1cfcf;

    // COLORS //
    --brt-text-color: #d1cfcf;
    --brt-accent-color: #d1cfcf;
    --brt-dark-text-color: white;
    --brt-secondary-color: #45b6ca;
    --brt-secondary-hover-color: #405d8e;
    --brt-secondary-focus-color: #2a3561;
    --brt-background-message-icon: #d1cfcf;
    --brt-link-color: #71c7d7;

    // BORDER COLORS //
    --brt-border-base-color: #e1e2e3;

    // BORDER RADIUS //
    --brt-border-radius: 8px;

    // BUTTON FLAT //
    --brt-background-flat-button: #6783b3;
    --brt-background-flat-button-hover: #4b6da7;
    --brt-background-flat-button-focus: #405d8e;
    --brt-background-flat-button-active: #31476c;
    --brt-background-flat-button-disabled: #263754;

    --brt-text-flat-button: #f6f4f4;

    --brt-warn-background-flat-button: #8f3908;
    --brt-warn-background-flat-button-hover: #6f2d06;
    --brt-warn-background-flat-button-focus: #431b04;
    --brt-warn-background-flat-button-active: #210d02;

    --brt-warn-text-flat-button: #f6f4f4;

    // BUTTON BASIC //
    --brt-border-basic-button-hover: #3f6ab312;
    --brt-border-basic-button-focus: #29457412;
    --brt-border-basic-button-active: #20355a10;

    --brt-text-basic-button: #8aa0c6;
    --brt-text-basic-button-hover: #a6b7d2;
    --brt-text-basic-button-focus: #a6b7d2;
    --brt-text-basic-button-active: #6783b3;
    --brt-text-basic-button-disabled: #31476c;

    --brt-warn-border-basic-button-hover: #8f3908;
    --brt-warn-border-basic-button-focus: #6f2d06;
    --brt-warn-border-basic-button-active: #210d02;

    --brt-warn-text-basic-button: #8f3908;
    --brt-warn-text-basic-button-hover: #6f2d06;
    --brt-warn-text-basic-button-focus: #431b04;
    --brt-warn-text-basic-button-active: #210d02;
    --brt-warn-text-basic-button-disabled: #cad3e4;

    // BUTTON STROKED //
    --brt-border-stroked-button: #8aa0c67a;
    --brt-border-stroked-button-hover: #636d7c93;
    --brt-border-stroked-button-focus: #8aa0c61f;
    --brt-border-stroked-button-active: #8aa0c61f;
    --brt-border-stroked-button-disabled: #3949647a;

    --brt-background-stroked-button-hover: #0e1018;
    --brt-background-stroked-button-focus: #1d212c;
    --brt-background-stroked-button-active: #6d88b8;

    --brt-text-stroked-button: #8aa0c6;
    --brt-text-stroked-button-hover: #a6b7d2;
    --brt-text-stroked-button-focus: #a6b7d2;
    --brt-text-stroked-button-active: #6783b3;
    --brt-text-stroked-button-disabled: #31476c;

    --brt-warn-border-stroked-button: #e89362;
    --brt-warn-border-stroked-button-hover: #edac87;
    --brt-warn-border-stroked-button-focus: #edac87;
    --brt-warn-border-stroked-button-active: #e07232;

    --brt-warn-background-stroked-button-hover: rgba(237, 172, 135, 0.6);
    --brt-warn-background-stroked-button-focus: rgba(223, 150, 107, 0.12);
    --brt-warn-background-stroked-button-active: rgba(223, 150, 107, 0.122);

    --brt-warn-text-stroked-button: #e89362;
    --brt-warn-text-stroked-button-hover: #edac87;
    --brt-warn-text-stroked-button-focus: #edac87;
    --brt-warn-text-stroked-button-active: #e07232;

    // OUTLINED BUTTON FAB //
    --brt-background-outlined-fab-button: #0a0c16;
    --brt-icon-outlined-fab-button: #8590b8;
    --brt-border-outlined-fab-button: #8590b8;

    // FAB BUTTON //
    --brt-background-fab-button: #6783b3;
    --brt-background-fab-button-hover: #4b6da7;
    --brt-background-fab-button-focus: #405d8e;
    --brt-background-fab-button-active: #31476c;
    --brt-background-fab-button-disabled: #263754;

    --brt-text-fab-button: #f6f4f4;
    --brt-border-fab-button: #6783b3;

    // RADIO //
    --brt-radio-icon: #45b6ca;
    --brt-radio-icon-hover: #21aac4;
    --brt-radio-icon-focus: #1c91a7;
    --brt-radio-icon-active: #45b6ca;

    --brt-radio-focus-ripple: #21aac4;
    --brt-radio-hover-ripple: #1c91a7;

    // CHECKBOX //
    --brt-checkbox-selected: #f6f4f4;
    --brt-checkbocx-disabled: #9b9ea3;

    --brt-checkbox-icon: #3f6ab3;
    --brt-checkbox-icon-hover: #294574;
    --brt-checkbox-icon-focus: #365a98;
    --brt-checkbox-icon-active: #294574;

    --brt-checkbox-border-color: #3f6ab3;
    --brt-checkbox-border-color-hover: #294574;
    --brt-checkbox-border-color-focus: #365a98;
    --brt-checkbox-border-color-active: #294574;

    --brt-checkbox-color-disabled: #9b9ea3;

    --brt-warn-checkbox-selected: #f6f4f4;
    --brt-warn-checkbocx-disabled: #9b9ea3;

    --brt-warn-checkbox-icon: #973700;
    --brt-warn-checkbox-icon-hover: #752b00;
    --brt-warn-checkbox-icon-focus: #461a00;
    --brt-warn-checkbox-icon-active: #230d00;

    --brt-warn-checkbox-border-color: #973700;
    --brt-warn-checkbox-border-color-hover: #752b00;
    --brt-warn-checkbox-border-color-focus: #461a00;
    --brt-warn-checkbox-border-color-active: #230d00;

    // SELECT //
    --brt-select-color: #a09f9f;

    --brt-select-hover-label-color: #a09f9f;
    --brt-select-hover-border: #7c8187;

    --brt-select-focus-label-color: #8aa0c6;
    --brt-select-focus-border: #8aa0c6;
    --brt-select-disabled-color: #9b9ea3;

    --brt-select-error-color: #e07232;
    --brt-select-error-hover-color: #e89362;
    --brt-select-error-focus-color: #e89362;
    --brt-select-error-text-color: #e07232;

    --brt-select-option-theme-color: #0a0c16;

    --brt-select-option-color: #d1cfcf;
    --brt-select-option-background: #0a0c16;

    --brt-select-option-hover-color: #d1cfcf;
    --brt-select-option-hover-background: #363d4766;

    --brt-select-option-focus-color: #71c7d7;
    --brt-select-option-focus-background: #0a0c16;

    --brt-select-option-active-color: #71c7d7;
    --brt-select-option-active-background: #363d4799;

    // ACCORDION //
    --brt-accordion-title-color: #8590b8;

    // MENU //
    --brt-menu-item-color: #f6f4f4;
    --brt-menu-item-color-hover: #d1cfcf;
    --brt-menu-item-color-focus: #71c7d7;
    --brt-menu-item-color-active: #71c7d7;

    --brt-menu-item-background: #0a0c16;
    --brt-menu-item-background-hover: #363d4766;
    --brt-menu-item-background-focus: #0a0c16;
    --brt-menu-item-background-active: #363d4799;

    --brt-warn-menu-item-color-hover: #752b00;
    --brt-warn-menu-item-color-focus: #752b00;
    --brt-warn-menu-item-color-active: #752b00;

    --brt-warn-menu-item-background-hover: #f6f4f4;
    --brt-warn-menu-item-background-focus: #f6f4f4;
    --brt-warn-menu-item-background-active: #fce6d9;

    // HINT //
    --brt-hint-color: #a09f9f;

    // SLIDE //
    --brt-slide-seleceted-color: #71c7d7;
    --brt-slide-track-color: #8bd9e733;
    --brt-slide-surface-color: #fbfafa;
    --brt-slide-unseleceted-color: #d1cfcf;
    --brt-slide-unseleceted-track-color: #2e343c;
    --brt-slide-unseleceted-pseudoaction-color: #16b2cf1f;

    // DIALOG //
    --brt-dialog-background: #0a0c16;
    --brt-dialog-border: rgba(84, 90, 99, 0.6);

    // ALERT //
    --brt-alert-success-background: #e5f2e4;
    --brt-alert-success-color: #2a5324;
    --brt-alert-success-border: #2a5324;
    --brt-alert-link-color: #16582c;

    // TABLE //
    --brt-table-background: #0a0c16;
    --brt-table-border-color: #545a63;
    --brt-table-text-color: #d1cfcf;

    --brt-table-head-background: #131516db;
    --brt-table-head-text-color: #a09f9f;

    // GAME //
    --brt-card-background: #2b3038;
    --brt-card-border-color: #545a63;
    --brt-card-text-color: #c8ccde;

    --brt-card-background-hightlighted: #1e2d3a;
    --brt-card-border-hightlighted: #a2aac9;
    --brt-card-color-hightlighted: #c8ccde;

    --brt-card-background-disabled: #141618;
    --brt-card-border-disabled: #38393a;
    --brt-card-color-disabled: #676b70e5;

    --brt-card-green-background: #1c2e1a;
    --brt-card-green-border-color: #96bc91;
    --brt-card-green-text-color: #96bc91;
  }
}

.theme-dark {
  .prefix {
    background: var(--brt-background-page);
    color: var(--mdc-outlined-text-field-input-text-color);
  }

  .infinity {
    & > path {
      fill: var(--mdc-outlined-text-field-input-text-color);
    }
  }
}
