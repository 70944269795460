:root {
  // theme-light
  // BACKGROUND //
  --brt-background-page: #fafafa;
  --brt-background-menu: #f6f4f4;
  --brt-background-footer: #233168;
  --brt-accent-opacity: rgba(0, 0, 0, 0.87);

  // COLORS //
  --brt-text-color: #363d47;
  --brt-accent-color: #363d47;
  --brt-dark-text-color: black;
  --brt-secondary-color: #364ca0;
  --brt-secondary-hover-color: #365a98;
  --brt-secondary-focus-color: #233168;
  --brt-background-message-icon: #364ca0;
  --brt-link-color: #b9e8f1;

  // BORDER COLORS //
  --brt-border-base-color: #82828299;

  // BORDER RADIUS //
  --brt-border-radius: 8px;

  // BUTTONS //
  --brt-button-height: 40px;

  // BUTTON FLAT //
  --brt-background-flat-button: #3f6ab3;
  --brt-background-flat-button-hover: #365a98;
  --brt-background-flat-button-focus: #294574;
  --brt-background-flat-button-active: #20355a;
  --brt-background-flat-button-disabled: #c5d2e8;

  --brt-text-flat-button: #f6f4f4;

  --brt-warn-background-flat-button: #973700;
  --brt-warn-background-flat-button-hover: #752b00;
  --brt-warn-background-flat-button-focus: #461a00;
  --brt-warn-background-flat-button-active: #230d00;

  --brt-warn-text-flat-button: #f6f4f4;

  // BUTTON BASIC //
  --brt-border-basic-button-hover: #3f6ab312;
  --brt-border-basic-button-focus: #29457412;
  --brt-border-basic-button-active: #20355a10;

  --brt-text-basic-button: #364ca0;
  --brt-text-basic-button-hover: #365a98;
  --brt-text-basic-button-focus: #294574;
  --brt-text-basic-button-active: #20355a;
  --brt-text-basic-button-disabled: #c5d2e8;

  --brt-warn-border-basic-button-hover: #973700;
  --brt-warn-border-basic-button-focus: #752b00;
  --brt-warn-border-basic-button-active: #230d00;

  --brt-warn-text-basic-button: #973700;
  --brt-warn-text-basic-button-hover: #752b00;
  --brt-warn-text-basic-button-focus: #461a00;
  --brt-warn-text-basic-button-active: #230d00;
  --brt-warn-text-basic-button-disabled: #c5d2e8;

  // BUTTON STROKED //
  --brt-border-stroked-button: #364ca0;
  --brt-border-stroked-button-hover: rgba(54, 90, 152, 0.12);
  --brt-border-stroked-button-focus: rgba(41, 69, 116, 0.12);
  --brt-border-stroked-button-active: rgba(32, 53, 90, 0.12);
  --brt-border-stroked-button-disabled: rgba(197, 210, 232, 0.48);

  --brt-background-stroked-button-hover: rgba(54, 90, 152, 0.04);
  --brt-background-stroked-button-focus: rgba(49, 71, 108, 0.04);
  --brt-background-stroked-button-active: rgba(38, 55, 84, 0.04);

  --brt-text-stroked-button: #364ca0;
  --brt-text-stroked-button-hover: #365a98;
  --brt-text-stroked-button-focus: #294574;
  --brt-text-stroked-button-active: #20355a;
  --brt-text-stroked-button-disabled: #c5d2e8;

  --brt-warn-border-stroked-button: rgba(117, 43, 0, 0.36);
  --brt-warn-border-stroked-button-hover: rgba(117, 43, 0, 0.12);
  --brt-warn-border-stroked-button-focus: rgba(117, 43, 0, 0.12);
  --brt-warn-border-stroked-button-active: rgba(117, 43, 0, 0.12);

  --brt-warn-background-stroked-button-hover: #6f2d0604;
  --brt-warn-background-stroked-button-focus: #461a0004;
  --brt-warn-background-stroked-button-active: #210d0204;

  --brt-warn-text-stroked-button: #8f3908;
  --brt-warn-text-stroked-button-hover: #752b00;
  --brt-warn-text-stroked-button-focus: #461a00;
  --brt-warn-text-stroked-button-active: #230d00;

  // OUTLINED BUTTON FAB //
  --brt-background-outlined-fab-button: #fafafa;
  --brt-icon-outlined-fab-button: #405296;
  --brt-border-outlined-fab-button: #405296;

  // FAB BUTTON //
  --brt-background-fab-button: #364ca0;
  --brt-background-fab-button-hover: #2e4188;
  --brt-background-fab-button-focus: #233168;
  --brt-background-fab-button-active: #1b2650;
  --brt-background-fab-button-disabled: #c3c9e3;

  --brt-text-fab-button: #f6f4f4;
  --brt-border-fab-button: #364ca0;

  // RADIO //
  --brt-radio-icon: #3f6ab3;
  --brt-radio-icon-hover: #294574;
  --brt-radio-icon-focus: #365a98;
  --brt-radio-icon-active: #294574;

  --brt-radio-focus-ripple: rgba(41, 69, 116, 0.4);
  --brt-radio-hover-ripple: rgba(54, 90, 152, 0.4);

  // CHECKBOX //
  --brt-checkbox-selected: #f6f4f4;
  --brt-checkbocx-disabled: #9b9ea3;

  --brt-checkbox-icon: #3f6ab3;
  --brt-checkbox-icon-hover: #294574;
  --brt-checkbox-icon-focus: #365a98;
  --brt-checkbox-icon-active: #294574;

  --brt-checkbox-border-color: #3f6ab3;
  --brt-checkbox-border-color-hover: #294574;
  --brt-checkbox-border-color-focus: #365a98;
  --brt-checkbox-border-color-active: #294574;

  --brt-checkbox-color-disabled: #9b9ea3;

  --brt-warn-checkbox-selected: #f6f4f4;
  --brt-warn-checkbocx-disabled: #9b9ea3;

  --brt-warn-checkbox-icon: #973700;
  --brt-warn-checkbox-icon-hover: #752b00;
  --brt-warn-checkbox-icon-focus: #461a00;
  --brt-warn-checkbox-icon-active: #230d00;

  --brt-warn-checkbox-border-color: #973700;
  --brt-warn-checkbox-border-color-hover: #752b00;
  --brt-warn-checkbox-border-color-focus: #461a00;
  --brt-warn-checkbox-border-color-active: #230d00;

  // SELECT //
  --brt-select-color: #363d47;

  --brt-select-hover-label-color: #545a63;
  --brt-select-hover-border: #c3c5c8;

  --brt-select-focus-label-color: #294574;
  --brt-select-focus-border: #294574;
  --brt-select-disabled-color: #9b9ea3;

  --brt-select-error-color: #973700;
  --brt-select-error-hover-color: #752b00;
  --brt-select-error-focus-color: #461a00;
  --brt-select-error-text-color: #973700;

  --brt-select-option-theme-color: #fbfafa;

  --brt-select-option-color: #363d47;
  --brt-select-option-background: #f6f4f4;

  --brt-select-option-hover-color: #363d47;
  --brt-select-option-hover-background: #fbfafa;

  --brt-select-option-focus-color: #294574;
  --brt-select-option-focus-background: #fbfafa;

  --brt-select-option-active-color: #1b2650;
  --brt-select-option-active-background: #e6eff8;

  // ACCORDION //
  --brt-accordion-title-color: #364ca0;

  // MENU //

  --brt-menu-item-color: #363d47;
  --brt-menu-item-color-hover: #363d47;
  --brt-menu-item-color-focus: #294574;
  --brt-menu-item-color-active: #1b2650;

  --brt-menu-item-background: #fbfafa;
  --brt-menu-item-background-hover: #f6f4f4;
  --brt-menu-item-background-focus: #fbfafa;
  --brt-menu-item-background-active: #e6eff8;

  --brt-warn-menu-item-color-hover: #752b00;
  --brt-warn-menu-item-color-focus: #752b00;
  --brt-warn-menu-item-color-active: #752b00;

  --brt-warn-menu-item-background-hover: #f6f4f4;
  --brt-warn-menu-item-background-focus: #f6f4f4;
  --brt-warn-menu-item-background-active: #fce6d9;

  // HINT //
  --brt-hint-color: #545a63;

  // SLIDE
  --brt-slide-seleceted-color: #3f6ab3;
  --brt-slide-track-color: #c5d2e8;
  --brt-slide-surface-color: #fbfafa;
  --brt-slide-unseleceted-color: #fbfafa;
  --brt-slide-unseleceted-track-color: #7c8187;
  --brt-slide-unseleceted-pseudoaction-color: #7c8187;

  // DIALOG //
  --brt-dialog-background: #fbfafa;
  --brt-dialog-border: #c3c5c8;

  // ALERT //
  --brt-alert-success-background: #e5f2e4;
  --brt-alert-success-color: #2a5324;
  --brt-alert-success-border: #2a5324;
  --brt-alert-link-color: #16582c;

  // TABLE //
  --brt-table-background: rgba(230, 239, 248, 0.2);
  --brt-table-border-color: rgba(0, 0, 0, 0.2);
  --brt-table-text-color: #363d47;

  --brt-table-head-background: rgba(230, 239, 248, 0.2);
  --brt-table-head-border-color: #545a63;

  // GAME //
  --brt-card-background: #fafafa;
  --brt-card-border-color: #364ca0;
  --brt-card-text-color: #364ca0;

  --brt-card-background-hightlighted: #e6eff8;
  --brt-card-border-hightlighted: #364ca0;
  --brt-card-color-hightlighted: #364ca0;

  --brt-card-background-disabled: #fbfafa;
  --brt-card-border-disabled: #c3c5c8;
  --brt-card-color-disabled: #9b9ea3;

  --brt-card-green-background: #e5f2e4;
  --brt-card-green-border-color: #478c3d;
  --brt-card-green-text-color: #478c3d;
}
