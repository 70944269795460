$height-top-bar: 80px;
$height-footer: 60px;

$width-layout-side-margin: 60px;
$margin-between-buttons: 20px;

$color-primary: #364CA0;
$color-white: #ffffff;
$color-disabled: #C3C5C8;
$color-button-secondary: #364CA0;
$color-button-disabled: lightgray;
$color-canvas-table: #364BA0;
$color-border-base: rgba(130, 130, 130, 0.6);
$color-text: #363D47;

$color-ligth-blue: lightblue;
$color-ligth-green: lightgreen;
$color-light-purple: #CBC3E3;

$box-shadow-in-layout: 0 0 10px 4px rgba(130, 130, 130, 0.4);

$border-radius-base: 8px;

:root {
    --card-background-color: #fafafa;
    --colors-default-normal: #3F6AB3;
    --color-navigation: #F6F4F4;
    --color-footer: #233168;
    --color-disabled: #C3C5C8;
    --color-text-secondary: #364CA0;
    --color-button-secondary: #364CA0;
    --color-table: #364CA0;
    --color-text-dark: $color-text;
    --text-icon-text-icon-light: #545A63;
    --color-text-voted: rgba(0, 0, 0, 0.87);
    --color-table-text: #545A63;
    --color-background-table: rgba(230, 239, 248, 0.2);

    --color-dark-blue: #233168;

    .theme-dark {
        --color-modal-text: rgba(255, 255, 255, 0.6);
        --card-background-color: #666666;
        --color-navigation: #363D47;
        --color-disabled: #666666;
        --color-footer: #363D47;
        --color-table-text: rgba(255, 255, 255, 0.87);
        --color-background-table: #424242;
        --text-icon-text-icon-light: #C3C5C8;
        --color-text-secondary: white;
        --color-button-secondary: white;
        --color-table: white;
        --color-text-voted: white;
        --color-text-dark: white;
    }
}
